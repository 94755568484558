<template>
  <div>
    <h2 v-html="title" v-if="title"></h2>
    <v-row>
      <v-col
        :cols="cols"
        :sm="sm"
        :md="md"
        :lg="lg"
        v-for="proposal in proposals"
        :key="proposal.proposalId"
      >
        <ProposalGridCard :proposal="proposal" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProposalGridCard from "./ProposalGridCard.vue";

export default {
  components: { ProposalGridCard },
  name: "ProposalGrid",
  props: {
    title: { type: String, required: false },
    proposals: { type: Array, required: true },
    cols: { type: Number, default: 12 },
    sm: { type: Number, default: 4 },
    md: { type: Number, default: 4 },
    lg: { type: Number, default: 4 }
  }
};
</script>
