<template>
  <v-card
    rounded="sm"
    :href="link"
    @click.prevent="handleLink"
    v-intersect.once="handleView"
    elevation="0"
  >
    <v-img :src="proposal.img" height="100%" :alt="proposal.name">
      <div
        class="image d-flex align-center justify-space-around"
        v-if="proposal.metaData.category_proposal_type.IMAGE"
      >
        <img
          :src="proposal.metaData.category_proposal_type.IMAGE"
          class="ma-0"
          style="max-width:150px;"
        />
        <div class="card-info-text pa-2 d-flex d-sm-none d-md-flex">
          <span v-html="proposal.content" class="white--text"></span>
        </div>
        <v-icon
          color="white"
          class="card-info-icon d-flex d-sm-none d-md-flex"
          small
          >$cardArrow</v-icon
        >
      </div>
    </v-img>
  </v-card>
</template>
<script>
import banner from "~/mixins/banner";
export default {
  name: "ProposalGridCard",
  // props: {
  //   proposal: { type: Object, required: false }
  // },
  mixins: [banner]
};
</script>
